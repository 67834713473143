
// import { id as appId } from "../../package.json"
var appId = "be82c58c3ef175e7d722406ec770be6317f525be";

const loggedInUser = () => {
    var user = localStorage.getItem("user");
    return JSON.parse(user);
};

const accessToken = () => {
    var token = localStorage.getItem("accessToken");
    return token;
};


const setCurrentRegion = (_) => {
    var _ = localStorage.setItem(`${appId}_currentLocation`, _);
    return _;
};

const getCurrentRegion = () => {
    var _ = localStorage.getItem(`${appId}_currentLocation`);
    return _;
};

const setCurrentDateSelection = (_) => {
    var _ = localStorage.setItem(`${appId}_currentDates`, _);
    return _;
};

const getCurrentDateSelection = () => {
    var _ = localStorage.getItem(`${appId}_currentDates`);
    return _;
};

const setCurrentFilters = (_) => {
    var _ = localStorage.setItem(`${appId}_currentFilters`, _);
    return _;
};

const getCurrentFilters = () => {
    var _ = localStorage.getItem(`${appId}_currentFilters`);
    return _;
};

export {
    loggedInUser,
    accessToken,
    setCurrentRegion,
    getCurrentRegion,
    setCurrentDateSelection,
    getCurrentDateSelection,
    setCurrentFilters,
    getCurrentFilters
};
