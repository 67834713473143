import { createContext, ReactNode, useState } from "react";

export const LangContext = createContext({
  lang: "",
  setLang: (arg) => {},
});


const LangContextProvider = ({ children }) => {
  const [lang, setLang] = useState("");

  return (
    <LangContext.Provider value={{ lang, setLang }}>
      {children}
    </LangContext.Provider>
  );
};

export default LangContextProvider;
