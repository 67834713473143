import React from "react";
import GlobalStyles from 'styles/GlobalStyles';
import { ThemeProvider } from "@mui/material";

import { css } from "styled-components/macro"; //eslint-disable-line
import { useRoutes } from "react-router-dom";
import routes from "routes";

import { gariProTheme } from "./theme";

export default function App() {
  const allowedPages = useRoutes(routes);
  const appTheme = gariProTheme();

  return (
    <ThemeProvider theme={appTheme}>
      <GlobalStyles />
      {allowedPages}
    </ThemeProvider>
  );
}
