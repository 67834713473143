import useAuth from "hooks/useAuth";
import React, { Fragment, ReactNode, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { loggedInUser } from "utils/state";


const AuthGuard = ({ children }) => {
  const { isAuthenticated } = useAuth();
  const userLoggedIn = loggedInUser();
  const { pathname } = useLocation();
  const [requestedLocation, setRequestedLocation] = useState(
    null
  );

  // if (!userLoggedIn) {
  //   if (pathname !== requestedLocation) {
  //     setRequestedLocation(pathname);
  //   }

  //   return <Login />;
  // }

  if (requestedLocation && pathname !== requestedLocation) {
    setRequestedLocation(null);
    return <Navigate to={requestedLocation} />;
  }
  return <Fragment>{children}</Fragment>;
};

export default AuthGuard;
