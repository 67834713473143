import React from "react";
import { createRoot } from 'react-dom/client';
import App from "./App";
import Modal from "react-modal";

import { AuthProvider } from "contexts/JWTAuthContext";
import TitleContextProvider from "contexts/TitleContext";
import LangContextProvider from "contexts/LangContext";
import { BrowserRouter } from "react-router-dom";


Modal.setAppElement("#root");

const container = document.getElementById('root');
const root = createRoot(container);
root.render(
    <AuthProvider>
        <LangContextProvider>
            <TitleContextProvider>
                <BrowserRouter>
                    <App />
                </BrowserRouter>
            </TitleContextProvider>
        </LangContextProvider>
    </AuthProvider>
);