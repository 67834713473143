
const TopNavList = [
  {
    title: "About",
    path: "/about",
  },
  {
    title: "Contact",
    path: "/contact-us",
  },
];

export default TopNavList;
