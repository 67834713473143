import { FC, Fragment, } from "react";
import { Outlet } from "react-router-dom";
import IndexNavBar from "./AppNavBar";
import {
  Card,
  Grid,
} from "@mui/material";

const AppDefaultLayout = ({ children }) => {

  return (
    <Fragment>
      <IndexNavBar />
      <Grid container spacing={0} sx={{
        minHeight: 600,
        paddingLeft: `calc(2%)`,
        paddingRight: `calc(2%)`,
        alignSelf: 'center'
      }} >

        <Grid item lg={8} md={8} sm={12} xs={12} sx={{
        }}>
          {children || <Outlet />}
        </Grid>

        <Grid item lg={4} md={4} sm={12} xs={12} sx={{
          padding: "0rem",
        }}>

        </Grid>
      </Grid>
      {/* <Footer /> */}
    </Fragment >
  );
};

export default AppDefaultLayout;
