import { FC, Fragment, } from "react";
import { Outlet } from "react-router-dom";
import IndexNavBar from "./NavBar.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";

const DefaultLayout = ({ children }) => {

  return (
    <Fragment>
      <IndexNavBar />
      {children || <Outlet />}
      {/* <Footer /> */}
    </Fragment >
  );
};

export default DefaultLayout;
