import { lazy, Suspense } from "react";
import AuthGuard from "./components/authentication/AuthGuard.js";
import DefaultLayout from "./components/layouts/DefaultLayout.js";
import AppDefaultLayout from "components/layouts/app/AppDefaultLayout.js";

const Loadable = (Component) => (props) =>
(
  <Suspense>
    <Component {...props} />
  </Suspense>
);

// authentication pages
// const Login = Loadable(lazy(() => import("./pages/authentication/Login")));

// landing pages
const Landing = Loadable(lazy(() => import("./pages/Landing.js")));
const About = Loadable(lazy(() => import("./pages/About.js")));
const ContactUs = Loadable(lazy(() => import("./pages/ContactUs.js")));

// app pages
const RequestEntry = Loadable(lazy(() => import("./pages/app/RequestEntry.js")));
const DescribeRecoveryRequest = Loadable(lazy(() => import("./pages/app/requests/DescribeRecoveryRequest.js")));
const DescribeTransportRequest = Loadable(lazy(() => import("./pages/app/requests/DescribeTransportRequest.js")));
const DescribeHiringRequest = Loadable(lazy(() => import("./pages/app/requests/DescribeHiringRequest.js")));

// error
// const Error = Loadable(lazy(() => import("./pages/404")));

// routes
const routes = [
  {
    path: "/",
    element: (
      <DefaultLayout />
    ),
    children: [
      {
        path: "",
        element: <Landing />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/contact-us",
        element: <ContactUs />,
      },
    ],
  },
  {
    path: "new",
    element: (
      <AuthGuard>
        <AppDefaultLayout />
      </AuthGuard>
    ),
    children: [
      {
        path: "",
        element: <RequestEntry/>
      },
      {
        path: "describe-it/recovery",
        element: <DescribeRecoveryRequest/>
      },
      {
        path: "describe-it/truck_hiring",
        element: <DescribeHiringRequest/>
      },
      {
        path: "describe-it/transport",
        element: <DescribeTransportRequest/>
      }
    ]
  }
];

export default routes;
