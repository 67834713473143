// import jwtDecode from "jwt-decode";
import { createContext, ReactNode, useEffect, useReducer } from "react";
import axios from "utils/axios";

// All types
const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
};

const Types = {
  Init: "INIT",
  Login: "LOGIN",
  Logout: "LOGOUT",
  Register: "REGISTER",
}

const isValidToken = (accessToken) => {
  if (!accessToken) return false;

  // const decodedToken = jwtDecode<{ exp }>(accessToken);
  // const currentTime = Date.now() / 1000;
  // return decodedToken.exp > currentTime;
  return true;
};

const setSession = (accessToken) => {
  if (accessToken) {
    localStorage.setItem("accessToken", accessToken);
  } else {
    localStorage.removeItem("accessToken");
  }
};

const setUserSession = (user) => {
  if (user) {
    localStorage.setItem("user", JSON.stringify(user));
  } else {
    localStorage.removeItem("user");
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        user: action.payload.user,
        isAuthenticated: action.payload.isAuthenticated,
      };
    }
    case "LOGIN": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }
    case "LOGOUT": {
      return {
        ...state,
        user: null,
        isAuthenticated: false,
      };
    }
    case "REGISTER": {
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  login: (username, password, lang) => Promise.resolve(),
  logout: () => { },
});



export const AuthProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);

  const login = async (username, password, lang) => {
    const response = await axios.post("token-auth/", {
      username,
      password,
      lang
    });
    //@ts-ignore
    const { token, user } = response.data;
    user.lang = lang;
    setSession(token);    
    setUserSession(user);

    dispatch({
      type: Types.Login,
      payload: {
        user,
      },
    });
  };


  const logout = () => {
    setSession(null);
    setUserSession(null);
    dispatch({ type: Types.Logout });
  };

  useEffect(() => {
    (async () => {
      try {
        const accessToken = window.localStorage.getItem("accessToken");

        if (accessToken && isValidToken(accessToken)) {
          setSession(accessToken);

          const response = await axios.get("/api/auth/profile");
          //@ts-ignore
          const { user } = response.data;

          dispatch({
            type: Types.Init,
            payload: {
              user,
              isAuthenticated: true,
            },
          });
        } else {
          dispatch({
            type: Types.Init,
            payload: {
              user: null,
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        dispatch({
          type: Types.Init,
          payload: {
            user: null,
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []);

  if (!state.isInitialized) {
    return <>Loading...</>;
  }

  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        logout,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
